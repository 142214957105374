import '../Haydi.css';
import Onsay2 from "../img/Onsay2.png"

export default function Baskan() {


    return (
<div id='BaskanB'>
    <img src={Onsay2} id='Onsay2Img'></img>
    <div id='BaskanContent'>
        <a name='BASKAN'> </a>
        <p id='Btitle'>İl Başkanımızdan</p><br></br>

        &emsp;&emsp;Kıymetli Hemşehrilerim,<br></br>
        <br></br>
        &emsp;Sizlerin de bildiği üzere, AK Parti 22 yıldır isminin karşılığını vermiş ve senelerdir siz değerli hemşerilerimizin teveccühünü kazanmış, varlığını milletine adamış partinin adıdır.
        ‍<br></br><br></br>
        &emsp;Recep Tayyip Erdoğan ve AK Parti dirilişin ve şahlanışın adıdır. 
        <br></br><br></br>
        &emsp;Davamız, milli, manevi ve medeniyet değerlerimizi koruyup milletimizin mutluluğu, huzuru, refahı ve onurunu esas alarak Türkiye’yi birlik, beraberlik ve tam bağımsızlık içerisinde hak ettiği en yüksek konuma ulaştırmak ve bu uğurda göstereceğimiz çabayla siz kıymetli milletimizin duasına talip olma davasıdır.
        <br></br><br></br>
        &emsp;Cumhurbaşkanımız Recep Tayyip Erdoğan’ın Türkiye Yüzyılı vizyon ve misyonunu örnek alan bizler de sizlerden aldığımız güçle Kütahya Yüzyılı’mızı başlattık. 
        <br></br><br></br>
        &emsp;Bu yolculukta sizlerin duasına, desteğine ve değerli fikirlerine ihtiyacımız var. Yeni ve büyük hedefleri beraber gerçekleştireceğiz. 
        <br></br><br></br>
        &emsp;Kütahya Yüzyılı’nda söz sahibi sensin. <br></br>
        &emsp;Haydi şimdi sıra sende!
        <br></br>
        <br></br>
        &emsp;AK Parti Kütahya İl Başkanı
        <p id='Btitle'>&emsp;Mustafa Önsay</p>
    </div>
</div>


)
}
