import '../Haydi.css';
import ImageSlider from '../ImageSlider';
import { SliderData } from '../SliderData';

export default function Gallery() {


    return (

<div id='Gallery'>
<a name='GALERI'> </a>


<div id='slider'>
        <ImageSlider slides={SliderData} />
      </div>

</div>


)
}
