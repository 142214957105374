import './Haydi.css';
import Ana from './comp/Ana';
import Gallery from './comp/Gallery';
import Baskan from './comp/Baskan';
import Form from './comp/Form';



import { FaFacebookF, FaInstagram, FaYoutube, FaTwitter, FaBars } from 'react-icons/fa';


function Haydi() {

  function leftTop(){

    if (window.innerWidth <= 1000) {
      document.getElementById("BGM").style.display = "none";
  } else {
    document.getElementById("BGM").style.display = "flex";
    document.getElementById("TitleMob").style.display = "none";
    document.getElementById("acces").style.display = "none";
  }


    document.getElementById("BGM").style.height = "100%"
    setTimeout(() => {
    document.getElementById("BGM").style.top = "0"
    document.getElementById("BGM").style.position = "fixed"
    document.getElementById("BGM").style.height = "0px"
    document.getElementById("Header").style.width = "100%"
    document.getElementById("slider").style.opacity = "1"
    document.getElementById("Ana").style.display = "flex"
    document.getElementById("Gallery").style.display = "flex"
    document.getElementById("Form").style.display = "block"
    document.getElementById("BaskanB").style.display = "flex"
    document.getElementById("TitleMobMenu").style.display = "flex"
    document.getElementById("Main").style.position = "relative"
    document.getElementById("Header").style.boxShadow = "5px 0px 10px 2px black"
    document.getElementById("Uye").style.display = "flex"
    }, 500);
    document.getElementById("Text").style.display = "none"
    document.getElementById("Text2").style.display = "flex"

  }

window.addEventListener('scroll', () => {
    if (window.pageYOffset > 50) {
      document.getElementById("Header").style.height = "50px";
      document.getElementById("BGM").style.opacity = "0";
      document.getElementById("OnsayImg").style.opacity = "0";
      document.getElementById("RTEimg").style.opacity = "0";
      document.getElementById("Ana").style.boxShadow = "none";

      setTimeout(() => {
        document.getElementById("BGM").style.display = "none";
        document.getElementById("acces").style.display = "flex";
      }, 500);
    }

    if (window.pageYOffset < 50)  {
      document.getElementById("Header").style.height = "175px";
      document.getElementById("BGM").style.opacity = "1";
      document.getElementById("OnsayImg").style.opacity = "1";
      document.getElementById("RTEimg").style.opacity = "1";
      setTimeout(() => {
        if (window.innerWidth <= 1000) {
          document.getElementById("BGM").style.display = "none";
      } else {
        document.getElementById("BGM").style.display = "flex";
        document.getElementById("acces").style.display = "none";
      }
      }, 500);
    }
  
    if (window.pageYOffset > 300)  {
        document.getElementById("Ana").style.boxShadow = "0px 1px 5px 1px black";
    }


    if (window.pageYOffset > 2000){
        document.getElementById("BaskanContent").style.animation = "leftani 1s both ease-in";
        document.getElementById("Btitle").style.animation = "rightani 1s both ease-in";
        document.getElementById("Onsay2Img").style.opacity = "1";
    }
    if (window.pageYOffset < 1500){
        document.getElementById("BaskanContent").style.animation = "none";
        document.getElementById("Btitle").style.animation = "none";
        document.getElementById("Onsay2Img").style.opacity = "0";
    }

  });


const navLinks = document.querySelectorAll('.no-underline');

window.addEventListener('scroll', () => {
    const fromTop = window.scrollY;

    navLinks.forEach(link => {
        const section = document.querySelector(link.getAttribute('href'));
        if (
            section.offsetTop <= fromTop + 100 &&
            section.offsetTop + section.offsetHeight > fromTop + 100
        ) {
            link.classList.add('active-link');
        } else {
            link.classList.remove('active-link');
        }
    });
});

function mobTitle(){
  document.getElementById("TitleMob").style.display = "flex"
  document.getElementById("TitleMobCloser").style.display = "flex"
}
function MobCloser(){
  document.getElementById("TitleMob").style.display = "none"
  document.getElementById("TitleMobCloser").style.display = "none"
}



  return (
<div id="Main">

  <div id='TitleMobMenu' onClick={mobTitle}>
    <FaBars className="Fabars" />
    <div id='TitleMob'>
      <a href='#ANASAYFA' className="no-underline"><section className='TT'>Ana Sayfa</section ></a>
      <a href='#GALERI' className="no-underline"><section className='TT'>Galeri</section ></a>
      <a href='#ANKET' className="no-underline"><section className='TT'>Anket</section ></a>
      <a href='#BASKAN' className="no-underline"><section className='TT' >İl Başkanımızdan</section ></a>
      <a href='https://www.akpartikutahya.com/' target='_blank' rel="noreferrer" className="no-underline"><section className='TT' >ÜYEMİZ OLUN</section ></a>
    </div>
  </div>
  <div id='TitleMobCloser' onClick={MobCloser}></div>


  <div id='BGM'onClick={leftTop}>
  <div id="Uye">ÜYEMİZ OLUN
    <a href='https://www.akpartikutahya.com/' target='_blank' rel="noreferrer" id='Adreces'> </a>
  </div>

    <div id='BG'>
      <div id='Logo'>

        <div id='TextM'>
          <div id='Text'>
            <svg width="275" height="275" xmlns="http://www.w3.org/2000/svg">
              <path id="circlePath" d="M137.5,137.5 m0,-125 a 125,125 0 1,0 0,250 a 125,125 0 1,0 0,-250" fill="transparent" />
              <text>
                <textPath href="#circlePath" fill="black" fontWeight="bold">
                HAYDİ ŞİMDİ SIRA SENDE
                </textPath>
              </text>
            </svg>
          </div>

          <div id='Text2'>
            <svg width="275" height="275" xmlns="http://www.w3.org/2000/svg">
              <path id="circlePath" d="M137.5,137.5 m0,-125 a 125,125 0 1,0 0,250 a 125,125 0 1,0 0,-250" fill="transparent" />
              <text>
                <textPath href="#circlePath" fill="#F89A20" fontWeight="bold">
                  HAYDİ ŞİMDİ &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp;&emsp; &emsp; &emsp; &emsp; &emsp; &emsp; SIRA SENDE
                </textPath>
              </text>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>

  <header id='Header'>
    <div id='HeaderAlt'></div>
    <div id='RTEimg'></div>
    <div id='TitleDes'>
      <a href='#ANASAYFA' className="no-underline"><section  id='Home' className='TT'>Ana Sayfa</section ></a>
      <a href='#GALERI' className="no-underline"><section  id='Galerim' className='TT'>Galeri</section ></a>
      <a href='#ANKET' className="no-underline"><section  id='AnketH' className='TT'>Anket</section ></a>
      <a href='#BASKAN' className="no-underline"><section  id='Baskans' className='TT' >Başkanımızdan</section ></a>
      <a href='https://www.akpartikutahya.com/' target='_blank' rel="noreferrer" className="no-underline"><section id='acces' className='TT' ><font color="#D9AD84">ÜYEMİZ OLUN</font></section ></a>
    </div>
    <div id='TitleRef'>

      <div id='TitleRefAlt'>
        <a href='https://www.facebook.com/profile.php?id=61551859359888&mibextid=ZbWKwL' target='_blank'rel="noreferrer"><FaFacebookF className="Face"></FaFacebookF></a>
        <a href='https://instagram.com/kutahya.yuzyili?igshid=OGQ5ZDc2ODk2ZA==' target='_blank'rel="noreferrer"><FaInstagram className="Face"></FaInstagram></a>
        <a href='https://www.youtube.com/channel/UCCvs9LbIdSjxSyPj-c22HLQ' target='_blank'rel="noreferrer"><FaYoutube className="Face"></FaYoutube></a>
        <a href='https://twitter.com/kutahyayuzyili' target='_blank'rel="noreferrer"><FaTwitter className="Face"></FaTwitter></a>
      </div>
    </div>
    <div id='OnsayImg'></div>
  </header>

  <Ana/>
  <Gallery/>
  <Form/>
  <Baskan/>

</div>
);
}

export default Haydi;
