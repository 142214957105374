import Img1 from "./img/1.jpg"
import Img2 from "./img/2.jpg"
import Img3 from "./img/3.jpg"
import Img4 from "./img/4.jpg"
import Img5 from "./img/5.jpg"
import Img6 from "./img/6.jpg"
import Img7 from "./img/7.jpg"
import Img8 from "./img/8.jpg"
import Img9 from "./img/9.jpg"

export const SliderData = [
  {
    image:Img1,
    text: "Kütahya Yüzyılında Söz Sahibi Sensin!"
  },
  {
    image:Img2,
    text: "Mahallede Gezek Var projemiz kapsamında Evliya Çelebi, Okçu ve Güveçci mahallelerinde ikamet eden hemşehrilerimiz ile gerçekleştirdik."
  },
  {
    image:Img3,
    text: "Kıymetli hemşehrilerimiz ile şehrimizi konuşup, şehrimizin lezzeti olan cimcik ikramında bulunduk."
  },
  {
    image:Img4,
    text: "Kuruluşun ve kurtuluşun diyarı şehrimiz için yapılacak ne varsa; hemşehrilerimiz ile mahalle mahalle hemhâl olup gönülleri, fikirleri dinlemeye devam ediyoruz."
  },
  {
    image:Img5,
    text: "Haydi, Şimdi Sıra Sende | Haydi, Şimdi Birlikte Yürüyelim"
  },
  {
    image:Img6,
    text: "Proje kapsamında Gönül Erlerimiz ile 22. toplantımızı gerçekleştirdik. "
  },
  {
    image:Img7,
    text:""
  },
  {
    image:Img8,
    text:""
  },
  {
    image:Img9,
    text:""
  }
]