import { useEffect } from 'react';
import '../Haydi.css';
import AnaIMG from '../img/Kutahya.png'

export default function Ana() {

    useEffect(() => {




        const imageContainer = document.getElementById("AnaIMGSvg");
        const mouseArea = document.getElementById("Ana");

        if (mouseArea) {
            mouseArea.addEventListener("mousemove", (e) => {
                const offsetX = (e.clientX - imageContainer.getBoundingClientRect().left) - (imageContainer.offsetWidth / 2);
                const offsetY = (e.clientY - imageContainer.getBoundingClientRect().top) - (imageContainer.offsetHeight / 2);

                const shadowX = offsetX / 50;
                const shadowY = offsetY / 50;

                imageContainer.style.filter = `drop-shadow(${shadowX}px ${shadowY}px 1px rgba(0, 0, 0, 0.3)`;
            })
        }
    }, []);

    return (
        <div id='Ana'>
            <a name='ANASAYFA'> </a>
            <div id='AnaBack'>
                <div id='AnaIMG'><img src={AnaIMG} alt='Svg' id='AnaIMGSvg' /></div>
            </div>
        </div>
    )
}