import '../Haydi.css';
export default function Form() {


    return (

<div id='Form'>
    <a name='ANKET'> </a>
    <div id='AnketM'>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe8aLI52woQBbKHhbHi2UzWSK6UnyweUJWW4LiwKYg5S_0hzA/viewform?embedded=true" width="100%" height="800" frameBorder="0" marginHeight="0" marginWidth="0">Yükleniyor…</iframe>
    </div>

</div>


)
}
